import './step-teaser.scss';
import { Slider } from '../../components/slider/slider';

class StepTeaser {
    constructor (element) {
        this.$stepTeaser = element;
        this.$stepItems = this.$stepTeaser.querySelectorAll('[data-step-teaser="slide"]');
    }

    initialize () {
        if (this.$stepItems.length >= 3 & window.innerWidth < 1024 || this.$stepItems.length > 3 & window.innerWidth >= 1024) {
            this.setSlider();
        }

        window.addEventListener('resize', () => {
            if (this.$stepItems.length < 4 & window.innerWidth < 1024 || this.$stepItems.length > 3 & window.innerWidth >= 1024) {
                this.setSlider();
            }
        });
    }

    setSlider () {
        this.$stepTeaser = new Slider(this.$stepTeaser, {
            speed: 500,
            indicators: true,
            slidesPerView: 'auto',
            centeredSlides: true,
            centeredSlidesBounds: true,
            initAttr: 'data-step-teaser'
        });
    }
}

export { StepTeaser };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $stepTeaser = $context.querySelectorAll('[data-step-teaser="root"]');

        for (let i = 0; i < $stepTeaser.length; i++) {
            const $$stepTeaser = new StepTeaser($stepTeaser[i]);
            $$stepTeaser.initialize();
        }
    }
});
